@import '~@edwin-edu/ui/vars';

.container {
  align-items: center;
  justify-content: center;
  font-size: $font-size-1;
  position: absolute;
  bottom: 0;
  left: calc(50% - 109px);
}

.link {
  color: $color-neutral-500;
  text-decoration: none;
}

.separator {
  margin: 0 $space-2x;
}
